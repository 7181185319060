import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { Link, generatePath } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import ErrorIcon from '@mui/icons-material/Error';

import { SearchItem, SearchItemButton } from '../style';
import { DashboardRoutes as ROUTES } from 'routes';
import { getFileType, FileType } from 'utils/utility';
import { ReactComponent as PDFIcon } from 'assets/images/pdf.svg';

const Resources = ({ resources }: any) => {
  return (
    <Box>
      {resources.map((resource: any) => {
        const { asset } = resource;
        const { type: fileType, extension: fileExtension } = getFileType(
          resource.assetExtension,
        );

        return (
          <SearchItem key={resource.id}>
            <Grid container alignItems='center'>
              <Grid className='flex-center' item sm={12} md={5}>
                <Avatar className='file-icon' alt={resource.fileName} imgProps={{'role' : 'img'}}>
                  {fileType === FileType.Image ? (
                    <ImageIcon
                      sx={{ color: theme => theme.palette.ternary.tertiary5 }}
                    />
                  ) : null}
                  {fileType === FileType.Document ? (
                    fileExtension === 'pdf' ? (
                      <PDFIcon style={{ flexShrink: 0 }} />
                    ) : (
                      <ArticleIcon
                        sx={{ color: theme => theme.palette.secondary.light }}
                      />
                    )
                  ) : null}
                  {fileType === FileType.Video ? (
                    <SmartDisplayIcon
                      sx={{ color: theme => theme.palette.primary.light }}
                    />
                  ) : null}
                  {fileType === FileType.Unknown ? <ErrorIcon /> : null}
                </Avatar>
                <Typography variant='h4'>{resource.fileName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Typography>{resource.description.substring(0, 35)}...</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <SearchItemButton>
                  <Button
                    color='secondary'
                    variant='text'
                    component={Link}
                    aria-label={`View ${resource.fileName}`}
                    to={{
                      pathname: generatePath(
                        ROUTES.SEARCH_RESOURCES_DETAILS.path,
                        { assetUuid: resource.uuid },
                      ),
                    }}
                  >
                    View File
                  </Button>
                </SearchItemButton>
              </Grid>
            </Grid>
          </SearchItem>
        );
      })}
    </Box>
  );
};

export default Resources;
